<script lang="ts">
  export let gap = "48px";
  export let duration = "30s";
  export let reverse = false;
</script>

<div class="container flex flex-row" style="gap: {gap}">
  {#each Array(2) as _}
    <div
      class="flex flex-row flex-shrink-0 justify-around m-w-full"
      class:content-reverse={reverse}
      class:content={!reverse}
      style="--gap: {gap}; --duration: {duration};"
    >
      <slot />
    </div>
  {/each}
</div>

<style lang="postcss">
  @keyframes scroll {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(calc(-100% - var(--gap)));
    }
  }

  @keyframes scroll-reverse {
    from {
      transform: translateX(calc(-100% - var(--gap)));
    }
    to {
      transform: translateX(0);
    }
  }
  .content {
    animation: scroll var(--duration) linear infinite;
    gap: var(--gap);
  }

  .content-reverse {
    animation: scroll-reverse var(--duration) linear infinite;
    gap: var(--gap);
  }

  .container:hover .content,
  .container:hover .content-reverse {
    animation-play-state: paused;
  }
</style>
