<script lang="ts">
  export let width: number = 94;
  export let height: number = 25;
</script>

<svg {width} {height} viewBox="0 0 94 25" xmlns="http://www.w3.org/2000/svg">
  <g id="Ramp svg" clip-path="url(#clip0_105_8779)">
    <g id="Group">
      <g id="Group_2">
        <g id="Group_3">
          <path
            id="Vector"
            d="M6.48501 8.43359C4.25683 8.43359 3.15936 10.4003 3.15936 13.0336V19.6336H0V5.73359H3.09285V9.33359H3.15936C3.82449 7.10025 5.1215 5.33359 7.15014 5.33359C8.58017 5.33359 9.17879 5.83359 9.17879 5.83359L7.74876 8.70025C7.74876 8.70025 7.31642 8.43359 6.48501 8.43359ZM44.4972 10.3669V19.6669H41.4376V11.5003C41.4376 9.16692 40.7059 7.93359 38.8436 7.93359C36.9147 7.93359 35.9835 9.50025 35.9835 12.5003V19.6669H32.9239V11.5003C32.9239 9.26692 32.1923 7.93359 30.3632 7.93359C28.2347 7.93359 27.4366 9.80025 27.4366 12.5003V19.6669H24.3437V5.73359H27.4366V8.90025H27.4698C27.9354 6.73359 29.2657 5.36692 31.4939 5.36692C33.6888 5.36692 35.1188 6.56692 35.6842 8.66692C36.2163 6.63359 37.5798 5.36692 39.7082 5.36692C42.6015 5.36692 44.4972 7.20025 44.4972 10.3669ZM15.3645 5.33359C12.5044 5.33359 10.6753 6.66692 9.81066 9.10025L12.4379 10.0669C12.9035 8.60025 13.9012 7.76692 15.431 7.76692C17.1271 7.76692 18.158 8.53359 18.158 9.66692C18.158 10.8336 17.3599 11.1003 15.564 11.4003C13.5686 11.7336 8.77971 11.8336 8.77971 15.8669C8.77971 18.2336 10.7418 20.0003 13.7017 20.0003C15.9299 20.0003 17.4596 19.1003 18.158 17.4003H18.1913V19.6669H21.2509V11.1003C21.2176 7.33359 19.3553 5.33359 15.3645 5.33359ZM18.2245 13.1003C18.2245 16.0336 16.7945 17.9003 14.4666 17.9003C12.837 17.9003 11.8726 17.0003 11.8726 15.6669C11.8726 14.4336 12.8703 13.5669 14.7991 13.2003C16.7613 12.8336 17.759 12.4003 18.2245 11.3003V13.1003ZM55.4053 5.36692C53.0773 5.36692 51.5143 6.66692 50.8491 8.60025V5.73359H47.59V25.0003H50.8159V16.8003H50.8491C51.5808 18.9003 53.0773 20.0669 55.4053 20.0669C59.13 20.0669 61.7905 17.0003 61.7905 12.6669C61.7905 8.33359 59.13 5.36692 55.4053 5.36692ZM54.6071 17.4669C52.0464 17.4669 50.6164 15.6003 50.6164 12.7003C50.6164 9.80025 52.2127 7.93359 54.6071 7.93359C57.0016 7.93359 58.5979 9.90025 58.5979 12.7003C58.5979 15.5003 57.0016 17.4669 54.6071 17.4669Z"
          />
          <path
            id="Vector_2"
            d="M93.7503 19.567V19.667H81.1461V19.567C82.9752 18.5337 84.2057 17.5003 85.3364 16.4003H90.5244L93.7503 19.567ZM90.6242 3.13367L87.4315 0.000335693H87.3318C87.3318 0.000335693 87.3983 5.83367 82.0107 11.167C76.7562 16.367 70.6038 16.4003 70.6038 16.4003V16.5003L73.8629 19.7003C73.8629 19.7003 79.9488 19.767 85.3031 14.467C90.6574 9.20033 90.6242 3.13367 90.6242 3.13367Z"
          />
        </g>
      </g>
    </g>
  </g>
  <defs>
    <clipPath id="clip0_105_8779">
      <rect width="93.75" height="25" />
    </clipPath>
  </defs>
</svg>
