<!--
  @component Encompasses the child in a fade on screens larger than 1400px.

  This is important for the landing page, since we have autoscrolling components
  that should continue to the edge of the screen, except on very screens larger
  than the maximum width setting for the `.marketing-container`.

  Note that this adds 64px of negative margin to the element. Make sure that the
  top-level container under the body of the page has `overflow-x-hidden` to
  avoid unwanted horizontal scrolling.
-->

<script lang="ts">
  // Used for the fade gradient. Must match the background color.
  export let color: "black" | "dark-gray";
</script>

<div class="marketing-contained-negate-ml marketing-contained-negate-mr">
  <div
    class="relative -mx-16 overflow-hidden"
    class:fade-black={color === "black"}
    class:fade-dark-gray={color === "dark-gray"}
  >
    <slot />
    <div class="absolute inset-y-0 left-0 w-16 gradleft" />
    <div class="absolute inset-y-0 right-0 w-16 gradright" />
  </div>
</div>

<style lang="postcss">
  .fade-black {
    --fade-color: theme("colors.black");
  }
  .fade-dark-gray {
    --fade-color: theme("colors.dark-gray");
  }

  .gradleft {
    background: linear-gradient(
      to right,
      var(--fade-color) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }
  .gradright {
    background: linear-gradient(
      to left,
      var(--fade-color) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }
</style>
