<script lang="ts">
  export let width: number = 113;
  export let height: number = 25;
</script>

<svg {width} {height} viewBox="0 0 113 25" xmlns="http://www.w3.org/2000/svg">
  <g id="substack_wordmark 3" clip-path="url(#clip0_105_8790)">
    <g id="Clip path group">
      <mask x="0" y="0" width="113" height="21">
        <g id="clip-path">
          <path
            id="Vector"
            d="M112.833 0.500137H0.333496V20.151H112.833V0.500137Z"
          />
        </g>
      </mask>
      <g mask="url(#mask0_105_8790)">
        <g id="Group">
          <path
            id="Vector_2"
            d="M21.1857 20.1511C20.2331 20.1539 19.2922 19.9388 18.4326 19.5219C17.6075 19.1378 16.8815 18.5645 16.3115 17.8471L17.945 16.4293C18.9461 17.5181 20.0439 18.0624 21.2385 18.0624C21.8735 18.0932 22.5041 17.9399 23.0566 17.6203C23.2751 17.4865 23.454 17.2953 23.5744 17.0668C23.6948 16.8383 23.7522 16.581 23.7405 16.3222C23.7386 16.104 23.6937 15.8884 23.6084 15.6882C23.523 15.488 23.3991 15.3072 23.2437 15.1565C22.9496 14.8383 22.6023 14.5755 22.2179 14.3804C21.8662 14.2107 21.3481 13.992 20.6635 13.7244C19.2899 13.207 18.2517 12.6269 17.5489 11.9841C17.2005 11.6644 16.9255 11.2709 16.7433 10.8313C16.5612 10.3918 16.4763 9.91694 16.4947 9.44039C16.4788 8.92801 16.587 8.4195 16.8099 7.95954C17.0327 7.49958 17.3633 7.10224 17.7726 6.80242C18.6239 6.15136 19.7085 5.82566 21.0265 5.82533C22.9062 5.82533 24.4169 6.51245 25.5587 7.88669L23.9774 9.22652C23.5996 8.80775 23.1393 8.4745 22.6263 8.24844C22.1133 8.02238 21.5592 7.90859 21.0001 7.91448C20.4289 7.8851 19.8616 8.02473 19.367 8.31642C19.1768 8.4321 19.0203 8.59723 18.9137 8.79479C18.8072 8.99236 18.7544 9.21521 18.7608 9.44039C18.7578 9.69948 18.8254 9.95435 18.9562 10.1768C19.1369 10.4447 19.3819 10.6613 19.668 10.806C20.0102 11.0045 20.2825 11.1517 20.4847 11.2477C20.687 11.3436 21.061 11.4994 21.6068 11.7151C23.0296 12.2332 24.1144 12.8222 24.8611 13.4822C25.6079 14.1422 25.9806 15.0707 25.9793 16.2676C26.0036 16.8129 25.8993 17.3562 25.6749 17.8522C25.4506 18.3482 25.1128 18.7825 24.6897 19.1189C23.8289 19.807 22.661 20.1511 21.1857 20.1511Z"
          />
          <path
            id="Vector_3"
            d="M36.5051 19.8296V18.5181C36.1102 19.0486 35.5949 19.4742 35.003 19.7586C34.4111 20.043 33.7603 20.1776 33.1061 20.1512C31.4726 20.1512 30.2473 19.6691 29.4302 18.7051C28.6131 17.7411 28.2048 16.1886 28.2051 14.0475V6.14894H30.4971V14.3948C30.4971 15.6265 30.7563 16.5323 31.2748 17.1122C31.5448 17.4051 31.8745 17.6347 32.2408 17.785C32.6071 17.9354 33.0012 18.0028 33.3958 17.9826C35.468 17.9826 36.5047 16.8046 36.506 14.4484V6.14894H38.7985V19.8296H36.5051Z"
          />
          <path
            id="Vector_4"
            d="M59.9596 20.1512C59.0069 20.154 58.0658 19.9389 57.206 19.522C56.3812 19.1375 55.6553 18.5643 55.085 17.8472L56.719 16.4295C57.7201 17.5182 58.8179 18.0626 60.0124 18.0626C60.6475 18.0933 61.278 17.9399 61.8306 17.6204C62.0491 17.4866 62.2279 17.2954 62.3483 17.0669C62.4687 16.8384 62.5261 16.5812 62.5145 16.3223C62.5118 16.104 62.4661 15.8885 62.3801 15.6885C62.2941 15.4885 62.1695 15.308 62.0138 15.1576C61.7197 14.8393 61.3723 14.5766 60.9879 14.3815C60.6362 14.2118 60.1179 13.9931 59.433 13.7255C58.0633 13.2078 57.0266 12.6277 56.3228 11.9852C55.9746 11.6654 55.6998 11.2718 55.5177 10.8323C55.3357 10.3928 55.2508 9.918 55.2691 9.44151C55.2526 8.92894 55.3605 8.42013 55.5833 7.95992C55.8061 7.49971 56.1369 7.10223 56.5465 6.80254C57.3981 6.15148 58.4828 5.82579 59.8004 5.82545C61.6801 5.82545 63.1909 6.51257 64.3326 7.88681L62.7519 9.22664C62.3738 8.80756 61.913 8.47412 61.3996 8.24805C60.8862 8.02199 60.3316 7.90837 59.7721 7.9146C59.2007 7.88521 58.6333 8.02484 58.1385 8.31655C57.9482 8.43203 57.7919 8.59721 57.6857 8.79491C57.5795 8.99245 57.5266 9.21501 57.5323 9.44002C57.5291 9.69913 57.5967 9.95406 57.7277 10.1764C57.9082 10.4443 58.153 10.6609 58.439 10.8057C58.7819 11.0041 59.0541 11.1514 59.2557 11.2473C59.4573 11.3432 59.8307 11.4994 60.3758 11.7157C61.7983 12.2338 62.8829 12.8228 63.6297 13.4828C64.3764 14.1428 64.7496 15.0709 64.7493 16.2672C64.7735 16.8126 64.6689 17.356 64.4443 17.852C64.2197 18.348 63.8816 18.7823 63.4582 19.1186C62.6014 19.807 61.4352 20.1512 59.9596 20.1512Z"
          />
          <path
            id="Vector_5"
            d="M94.6328 20.1511C92.9113 20.1511 91.5501 19.5226 90.549 18.2654C89.5479 17.0083 89.0472 15.255 89.0469 13.0054C89.0469 10.7558 89.5517 8.99779 90.5612 7.73141C91.5708 6.46502 92.9628 5.83133 94.7373 5.83034C97.1261 5.83034 98.6984 6.89277 99.4542 9.01764L97.2936 9.82055C96.8357 8.60676 95.9924 7.99987 94.7637 7.99987C93.6392 7.99987 92.7961 8.43722 92.2343 9.31191C91.6725 10.1866 91.3918 11.4178 91.3921 13.0054C91.3921 14.5764 91.6732 15.7945 92.2353 16.6596C92.4947 17.0764 92.858 17.416 93.2883 17.6441C93.7186 17.8721 94.2007 17.9805 94.6855 17.9583C96.0908 17.9583 97.0041 17.2801 97.4255 15.9237L99.5602 16.6462C99.2794 17.6942 98.6509 18.6115 97.7811 19.243C96.851 19.8727 95.7499 20.1903 94.6328 20.1511Z"
          />
          <path
            id="Vector_6"
            d="M51.7742 7.72877C51.2906 7.11266 50.6719 6.61991 49.9675 6.28984C49.2632 5.95976 48.4926 5.80151 47.7173 5.8277C47.082 5.81366 46.4513 5.94051 45.8692 6.19942C45.287 6.45833 44.7675 6.84306 44.3467 7.32682V0.500137L44.3125 0.516513L42.0508 1.80176V19.8294H44.3433V18.6519C44.7914 19.1223 45.3274 19.4973 45.9199 19.7547C46.5124 20.0121 47.1496 20.1468 47.794 20.151C49.5154 20.151 50.8637 19.5307 51.8387 18.2901C52.8138 17.0495 53.3011 15.2781 53.3008 12.9759C53.3027 10.7459 52.7939 8.99681 51.7742 7.72877ZM50.0748 16.657C49.7926 17.0877 49.4049 17.4364 48.9498 17.6688C48.4948 17.9012 47.988 18.0093 47.4794 17.9824C46.8492 17.9828 46.2304 17.8115 45.6876 17.4862C45.1313 17.1622 44.668 16.696 44.3438 16.134V9.79012C44.6537 9.27783 45.0779 8.84674 45.5821 8.53167C46.1454 8.15666 46.806 7.9609 47.4794 7.96944C48.6209 7.96944 49.486 8.39786 50.0748 9.25469C50.6636 10.1115 50.9578 11.3521 50.9575 12.9764C50.9575 14.5472 50.6632 15.774 50.0748 16.657Z"
          />
          <path
            id="Vector_7"
            d="M111.306 6.14882H108.565L104.191 10.863V0.588028L101.898 1.89312V19.8295H104.191V13.6444L106.167 11.5563L110.33 19.829H112.833L107.774 9.86958L111.306 6.14882Z"
          />
          <path
            id="Vector_8"
            d="M73.4392 17.651C73.0329 17.856 72.5878 17.969 72.1344 17.9824C71.5899 17.9824 71.2211 17.8217 71.0279 17.5001C70.8098 17.071 70.7096 16.5902 70.7378 16.1082V8.18381H74.0595V6.14926H70.7378V0.500137L68.4453 1.80523V6.14876H66.2588V8.18332H68.4453V16.8843C68.4453 19.0631 69.3939 20.152 71.2912 20.151C71.829 20.1536 72.3649 20.0869 72.8862 19.9525C73.292 19.8453 73.6866 19.6984 74.0644 19.5138V19.5059L73.4392 17.651Z"
          />
          <path
            id="Vector_9"
            d="M84.4754 19.8293V18.5714C83.4562 19.5882 82.0834 20.1558 80.6549 20.1509C79.232 20.1509 78.0947 19.7539 77.2427 18.9599C76.3908 18.1659 75.9648 17.0281 75.9648 15.5463C75.9648 14.0652 76.4478 12.9404 77.4137 12.1719C78.4435 11.3831 79.7083 10.9765 80.9973 11.0202C82.213 10.9983 83.4128 11.303 84.4754 11.9035V10.9408C84.4754 9.79879 84.2251 9.00019 83.7246 8.54498C83.224 8.08976 82.4994 7.86216 81.5508 7.86216C80.133 7.88427 78.7585 8.36219 77.6247 9.2273L76.9663 7.27363C78.4122 6.3075 80.1099 5.80382 81.8404 5.8276C85.0375 5.8276 86.636 7.46964 86.636 10.7537V19.8288L84.4754 19.8293ZM80.9973 18.089C82.3146 18.089 83.474 17.4912 84.4754 16.2956V13.9395C83.5276 13.3381 82.4313 13.0225 81.3138 13.0294C80.5023 12.9947 79.7005 13.2195 79.0213 13.6721C78.7279 13.8817 78.4918 14.1636 78.335 14.4913C78.1783 14.8191 78.1062 15.182 78.1254 15.5458C78.1114 15.8906 78.1712 16.2344 78.3009 16.5534C78.4305 16.8724 78.6268 17.159 78.8763 17.3933C79.3771 17.8571 80.0841 18.089 80.9973 18.089Z"
          />
          <path
            id="Vector_10"
            d="M12.4349 9.29713H0.333496V10.9516H12.4349V9.29713Z"
          />
          <path
            id="Vector_11"
            d="M0.333496 12.4502V20.1418L6.38397 16.7064L12.4354 20.1418V12.4502H0.333496Z"
          />
          <path
            id="Vector_12"
            d="M12.4349 6.14369H0.333496V7.79813H12.4349V6.14369Z"
          />
        </g>
      </g>
    </g>
  </g>
  <defs>
    <clipPath id="clip0_105_8790">
      <rect
        width="112.5"
        height="19.6543"
        transform="translate(0.333496 0.5)"
      />
    </clipPath>
  </defs>
</svg>
